// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}


.container {
    display: flex;
    flex-direction: column;     /* Stack sections vertically (column) */
    justify-content: flex-start; /* Align items at the top */
    align-items: center;        /* Center items horizontally */
    padding-top: 20px;          /* Add some padding from the top */
}

.product-section {
    background-color: white;
    max-width: 600px;
    width: 100%;                /* Ensure full width within the max-width */
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 30px;
    margin: 20px 0;             /* Space between sections */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.product-section h2 {
    color: #2c3e50;
    margin-bottom: 10px;
}

.product-section p {
    font-size: 1.2em;
    color: #7f8c8d;
}

.buy-button {
    display: inline-block;
    padding: 12px 24px;
    background-color: #8ec879;
    color: white;
    font-size: 1.1em;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.buy-button:hover {
    background-color: #218838;
}
`, "",{"version":3,"sources":["webpack://./src/components/Buy.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,SAAS;IACT,UAAU;AACd;;;AAGA;IACI,aAAa;IACb,sBAAsB,MAAM,uCAAuC;IACnE,2BAA2B,EAAE,2BAA2B;IACxD,mBAAmB,SAAS,8BAA8B;IAC1D,iBAAiB,WAAW,kCAAkC;AAClE;;AAEA;IACI,uBAAuB;IACvB,gBAAgB;IAChB,WAAW,iBAAiB,2CAA2C;IACvE,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,cAAc,cAAc,2BAA2B;IACvD,yCAAyC;IACzC,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;IAChB,qBAAqB;IACrB,kBAAkB;IAClB,sCAAsC;AAC1C;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["html, body {\n    height: 100%;\n    margin: 0;\n    padding: 0;\n}\n\n\n.container {\n    display: flex;\n    flex-direction: column;     /* Stack sections vertically (column) */\n    justify-content: flex-start; /* Align items at the top */\n    align-items: center;        /* Center items horizontally */\n    padding-top: 20px;          /* Add some padding from the top */\n}\n\n.product-section {\n    background-color: white;\n    max-width: 600px;\n    width: 100%;                /* Ensure full width within the max-width */\n    border: 1px solid #ccc;\n    border-radius: 10px;\n    padding: 30px;\n    margin: 20px 0;             /* Space between sections */\n    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);\n    text-align: center;\n}\n\n.product-section h2 {\n    color: #2c3e50;\n    margin-bottom: 10px;\n}\n\n.product-section p {\n    font-size: 1.2em;\n    color: #7f8c8d;\n}\n\n.buy-button {\n    display: inline-block;\n    padding: 12px 24px;\n    background-color: #8ec879;\n    color: white;\n    font-size: 1.1em;\n    text-decoration: none;\n    border-radius: 5px;\n    transition: background-color 0.3s ease;\n}\n\n.buy-button:hover {\n    background-color: #218838;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
