import React, { useState, useEffect } from "react";
import './Home.css'; // Import the CSS file for the Home page
import profileImage from '../assets/profileImage.png'; // Import the profile image
import topImage from '../assets/topimage.png'; // Import the top image

const Home = () => {
  const [homeContent, setHomeContent] = useState({ title: '', description: '' });
  const [aboutContent, setAboutContent] = useState({ name: '', description: '' });
  const [faqContent, setFaqContent] = useState([]);
  const [benefitContent, setBenefitContent] = useState({ title: '', benefits: [] });

  // Fetch Home content
  useEffect(() => {
    fetch('/homeContent.json')
      .then(response => response.json())
      .then(data => setHomeContent(data));
  }, []);

  // Fetch About content
  useEffect(() => {
    fetch('/api/aboutContent')
      .then(response => response.json())
      .then(data => setAboutContent(data));
  }, []);

  // Fetch FAQ content
  useEffect(() => {
    const fetchFaqContent = async () => {
      const response = await fetch('/faqContent.json');
      const data = await response.json();
      setFaqContent(data);
    };

    fetchFaqContent();
  }, []);

  // Fetch Pros content
  useEffect(() => {
    fetch('/benefitContent.json')
      .then(response => response.json())
      .then(data => setBenefitContent(data));
  }, []);

  return (
    <div className="home-page">
      <div className="top-image-container">
        <img src={topImage} alt="Top Site" className="top-image" />
      </div>

      <div id="home" className="section-container home-container">
        <h1 className="section-title">{homeContent.title}</h1>
        <p className="section-content">{homeContent.description}</p>
      </div>

      <div id="about" className="section-container about-container">
        <h1 className="section-title">Um mig</h1>
        <img src={profileImage} alt="Profile" className="about-image" />
        <h2 className="name">{aboutContent.name}</h2>
        <p className="section-content about-description">{aboutContent.description}</p>
      </div>

      <div id="pros" className="section-container pros-container">
        <h1 className="section-title">{benefitContent.title}</h1>
        <ul className="pros-benefits">
          {benefitContent.benefits.map((benefit, index) => (
            <li key={index}>{benefit}</li>
          ))}
        </ul>
      </div>

      <div id="contact" className="section-container contact-container">
        <h1 className="section-title">Algengar spurningar</h1>
        <div className="contact-faq">
          {faqContent.map((faq, index) => (
            <div key={index}>
              <h2 className="contact-question">{faq.question}</h2>
              <p className="contact-answer">{faq.answer}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
