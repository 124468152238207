import React from "react";
import "./Footer.css";
import '@fortawesome/fontawesome-free/css/all.min.css';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <a href="https://www.facebook.com/profile.php?id=61558664951843" target="_blank" rel="noopener noreferrer" className="footer-link">
          <i className="fab fa-facebook-f"></i>
          <span>Ungirofurhugar</span> {/* Wrap the text in span */}
        </a>
        <a href="https://www.instagram.com/ungir.ofurhugar" target="_blank" rel="noopener noreferrer" className="footer-link">
          <i className="fab fa-instagram"></i>
          <span>ungir.ofurhugar</span> {/* Wrap the text in span */}
        </a>
        <a href="mailto:ungirofurhugar@ungirofurhugar.is" className="footer-link">
          <i className="fas fa-envelope"></i>
          <span>ungirofurhugar@ungirofurhugar.is</span> {/* Wrap the text in span */}
        </a>
      </div>
    </div>
  );
};

export default Footer;
