import React, { useState, useEffect } from 'react';
import './AdminVideoManager.css';

const AdminVideoManager = () => {
  const [videos, setVideos] = useState([]);
  const [file, setFile] = useState(null);

  useEffect(() => {
    fetchVideos();
  }, []);

  const fetchVideos = async () => {
    const response = await fetch('/api/videos');
    const data = await response.json();
    setVideos(data);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('video', file);

    const response = await fetch('/api/videos/upload', {
      method: 'POST',
      body: formData,
      credentials: 'include'
    });

    if (response.ok) {
      alert('Video uploaded successfully');
      fetchVideos();
    } else {
      alert('Failed to upload video');
    }
  };

  const handleDelete = async (filename) => {
    const response = await fetch(`/api/videos/${filename}`, {
      method: 'DELETE',
      credentials: 'include'
    });

    if (response.ok) {
      alert('Video deleted successfully');
      fetchVideos();
    } else {
      alert('Failed to delete video');
    }
  };

  return (
    <div className="admin-video-manager">
      <h1>Admin Video Manager</h1>
      <form onSubmit={handleUpload}>
        <input type="file" onChange={handleFileChange} />
        <button type="submit">Upload Video</button>
      </form>
      <ul>
        {videos.map((video) => (
          <li key={video.filename}>
            <a href={video.url} target="_blank" rel="noopener noreferrer">{video.filename}</a>
            <button onClick={() => handleDelete(video.filename)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminVideoManager;
