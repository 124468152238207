import React from 'react';
import AdminVideoManager from './AdminVideoManager';
import AdminExtraMaterialManager from './AdminExtraMaterialManager';
import AdminLesefniManager from './AdminLesefniManager';
import AdminAboutEditor from './AdminAboutEditor';
import AdminHomeEditor from './AdminHomeEditor';
import AdminBenefitEditor from './AdminBenefitEditor';
import AdminFaqEditor from './AdminFaqEditor';
import AdminUserEditor from './AdminUserEditor';
import AdminGenerateSignupKey from './AdminGenerateSignupKey';
import AdminReviewManager from './AdminReviewManager';


import './AdminDashboard.css';

const AdminDashboard = () => {
  return (
    <div className="admin-dashboard">
      <h1>Admin Dashboard</h1>
      <p>Welcome, Admin! You can edit stuff here.</p>

      <div className="admin-section">
        <h2>Generate Signup Key</h2>
        <AdminGenerateSignupKey />
      </div>

      <div className="admin-section">
        <h2>Manage Users</h2>
        <AdminUserEditor />
      </div>

      <div className="admin-section">
        <h2>Manage Reviews</h2>
        <AdminReviewManager />
      </div>
      
      <div className="admin-section">
        <h2>Manage Videos</h2>
        <AdminVideoManager />
      </div>

      <div className="admin-section">
        <h2>Manage Extra Materials</h2>
        <AdminExtraMaterialManager />
      </div>

      <div className="admin-section">
        <h2>Manage Lesefni</h2>
        <AdminLesefniManager />
      </div>

      <div className="admin-section">
        <h2>Edit About Content</h2>
        <AdminAboutEditor />
      </div>

      <div className="admin-section">
        <h2>Edit Helstu kostir</h2>
        <AdminBenefitEditor />
       
      </div>

      <div className="admin-section">
        <h2>Edit Home page</h2>
        <AdminHomeEditor />
       
      </div>

      <div className="admin-section">
        <h2>Edit Upplýsingar</h2>
        <AdminFaqEditor />
       
      </div>

    </div>
  );
};

export default AdminDashboard;
