import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Form.css';

const Signin = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/auth/signin', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('userToken', data.token); // Store the user token in local storage
        if (data.isAdmin) {
          navigate('/admin-dashboard'); // Redirect to admin dashboard
        } else {
          navigate('/dashboard'); // Redirect to regular user dashboard
        }
      } else {
        const data = await response.json();
        alert(data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="form-container">
      <h1 className="form-title">Innskráning</h1>
      <form onSubmit={handleSubmit} className="form">
        <label>Email:
          <input type="email" name="email" value={formData.email} onChange={handleChange} />
        </label>
        <label>Lykilorð:
          <input type="password" name="password" value={formData.password} onChange={handleChange} />
        </label>
        <button type="submit" className="form-button">Innskrá</button>
      </form>
      <p className="redirect-text">
        Búa til aðgang:  <a href="/signup" className="redirect-link">Nýskráning</a>
      </p>
    </div>
  );
};

export default Signin;
