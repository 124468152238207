import React, { useState, useEffect } from "react";
import './AdminAboutEditor.css';

const AdminAboutEditor = () => {
  const [aboutContent, setAboutContent] = useState({ name: '', description: '' });

  useEffect(() => {
    fetch('/api/aboutContent')
      .then(response => response.json())
      .then(data => setAboutContent(data));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAboutContent(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch('/api/aboutContent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(aboutContent),
      credentials: 'include'
    });
    if (response.ok) {
      alert('Content updated successfully');
    } else {
      alert('Failed to update content');
    }
  };

  return (
    <div className="admin-about-editor">
      <h2>Edit Um mig</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>nafn</label>
          <input type="text" name="name" value={aboutContent.name} onChange={handleChange} />
        </div>
        <div>
          <label>Texti</label>
          <textarea name="description" value={aboutContent.description} onChange={handleChange} />
        </div>
        <button type="submit">Save</button>
      </form>
    </div>
  );
};

export default AdminAboutEditor;
