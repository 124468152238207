import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Function to dynamically load Google Ads script
const loadGoogleAdsScript = () => {
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=AW-710-422-0452`;
  document.head.appendChild(script);

  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
    window.gtag = gtag;
    gtag('js', new Date());
    gtag('config', 'AW-710-422-0452');
  };
};

// Load script when the app starts
loadGoogleAdsScript();

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
