// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-faq-editor {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .faq-item {
    margin-bottom: 20px;
  }
  
  .faq-input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .faq-textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .remove-button,
  .save-button,
  .add-button,
  .save-all-button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .remove-button {
    background-color: #ff4d4d;
  }
  
  .remove-button:hover,
  .save-button:hover,
  .add-button:hover,
  .save-all-button:hover {
    opacity: 0.9;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/AdminFaqEditor.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,cAAc;IACd,aAAa;EACf;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,eAAe;IACf,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,eAAe;IACf,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;;;;IAIE,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;;;;IAIE,YAAY;EACd","sourcesContent":[".admin-faq-editor {\n    width: 100%;\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 20px;\n  }\n  \n  .faq-item {\n    margin-bottom: 20px;\n  }\n  \n  .faq-input {\n    width: 100%;\n    padding: 10px;\n    margin-top: 5px;\n    margin-bottom: 10px;\n    font-size: 16px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  \n  .faq-textarea {\n    width: 100%;\n    height: 100px;\n    padding: 10px;\n    margin-top: 5px;\n    margin-bottom: 10px;\n    font-size: 16px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  \n  .remove-button,\n  .save-button,\n  .add-button,\n  .save-all-button {\n    background-color: #007bff;\n    color: white;\n    padding: 10px 15px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    margin-bottom: 10px;\n  }\n  \n  .remove-button {\n    background-color: #ff4d4d;\n  }\n  \n  .remove-button:hover,\n  .save-button:hover,\n  .add-button:hover,\n  .save-all-button:hover {\n    opacity: 0.9;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
