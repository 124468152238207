import React from 'react';
import './Buy.css';

const Buy = () => {
  return (
    <div className="buy-page">
      {/* Black Friday Sale Banner */}
      {/* <div className="sale-banner">
        <h1>🎉 Black Friday afsláttur 🎉</h1>
        <p>20% afsláttur af námskeiðum, gildir út mánudaginn 2. des</p>
      </div> */}

      <div className="container">
        {/* Section for Product 1 */}
        <div className="product-section">
          <h2>Námskeið</h2>
          <p className="price">
          <span >12.500 kr.</span>
            {/* <span className="original-price">29.990 kr.</span> */}
            {/* <span className="sale-price">23.992 kr.</span> */}
          </p>
          <a href="https://securepay.borgun.is/SecurePay/Ticket.aspx?ticket=MnokP1wvh" className="buy-button">
            Kaupa námskeið
          </a>
        </div>

        {/* Section for Product 2 */}
        {/* <div className="product-section">
          <h2>Námskeið án tölvupóstsamskipta</h2>
          <p className="price">
          <span >18.990 kr.</span> */}
            {/* <span className="original-price">18.990 kr.</span> */}
            {/* <span className="sale-price">15.192 kr.</span> */}
          {/* </p>
          <a href="https://securepay.borgun.is/SecurePay/Ticket.aspx?ticket=aVCtatPgQ" className="buy-button">
            Kaupa námskeið
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default Buy;
