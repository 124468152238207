import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './WriteReviews.css';

const WriteReviews = () => {
    const navigate = useNavigate();
    const [review, setReview] = useState('');
    const [name, setName] = useState(''); // State to store the user's name

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/submit-review', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ name, review }),
            });
            if (response.ok) {
                navigate('/dashboard');
            } else {
                const data = await response.json();
                alert(data.error);
            }
        } catch (error) {
            console.error('Error submitting review:', error);
        }
    };

    return (
        <div className="write-reviews">
            <h1>Skrifa umsögn</h1>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    className="write-reviews-name-input"
                    placeholder="Nafn"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
                <textarea
                    className="write-reviews-textarea"
                    placeholder="Skrifaðu umsögnina þína hér..."
                    value={review}
                    onChange={(e) => setReview(e.target.value)}
                    required
                />
                <button type="submit" className="write-reviews-submit-button">
                    Senda umsögn
                </button>
            </form>
        </div>
    );
};

export default WriteReviews;
