import React from 'react';
import './Buy.css';

const Buy = () => {
  return (
    <div>
      {/* Section for Product 1 */}
      <div className="container">

      <div className="product-section">
        <h2>Námskeið með tölvupóstsamskiptum</h2>
        <p>29.990 kr.</p>
        <a href="https://securepay.borgun.is/SecurePay/Ticket.aspx?ticket=RcGpweAyb" className="buy-button">
          Kaupa námskeið
        </a>
      </div>

      {/* Section for Product 2 */}
      <div className="product-section">
        <h2>Námskeið án tölvupóstsamskipta</h2>
        <p>18.990 kr.</p>
        <a href="https://securepay.borgun.is/SecurePay/Ticket.aspx?ticket=aVCtatPgQ" className="buy-button">
          Kaupa námskeið
        </a>
      </div>
        </div>
    </div>
  );
};

export default Buy;
