import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Form.css';

const Signup = () => {
  const [formData, setFormData] = useState({ name: '', email: '', password: '', signupKey: '' });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/auth/signup', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        navigate('/signin');
      } else {
        const data = await response.json();
        alert(data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="form-container">
      <h1 className="form-title">Nýskráning</h1>
      <form onSubmit={handleSubmit} className="form">
        <label>Nafn:
          <input type="text" name="name" value={formData.name} onChange={handleChange} />
        </label>
        <label>Email:
          <input type="email" name="email" value={formData.email} onChange={handleChange} />
        </label>
        <label>Lykilorð:
          <input type="password" name="password" value={formData.password} onChange={handleChange} />
        </label>
        <label>Notenda lykill:
          <input type="text" name="signupKey" value={formData.signupKey} onChange={handleChange} />
        </label>
        <button type="submit" className="form-button">Nýskrá</button>
      </form>
      <p className="redirect-text">
        Ef þú átt nú þegar aðgang:  <a href="/signin" className="redirect-link">Innskráning</a>
      </p>
    </div>
  );
};

export default Signup;
