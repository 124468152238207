import React, { useState, useEffect } from 'react';
import './AdminLesefniManager.css';

const AdminLesefniManager = () => {
  const [lesefni, setLesefni] = useState([]);
  const [file, setFile] = useState(null);

  useEffect(() => {
    fetchLesefni();
  }, []);

  const fetchLesefni = async () => {
    const response = await fetch('/api/pdfs');
    const data = await response.json();
    setLesefni(data);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('pdf', file);

    const response = await fetch('/api/pdfs/upload', {
      method: 'POST',
      body: formData,
      credentials: 'include'
    });

    if (response.ok) {
      alert('PDF uploaded successfully');
      fetchLesefni();
    } else {
      alert('Failed to upload PDF');
    }
  };

  const handleDelete = async (filename) => {
    const response = await fetch(`/api/pdfs/${filename}`, {
      method: 'DELETE',
      credentials: 'include'
    });

    if (response.ok) {
      alert('PDF deleted successfully');
      fetchLesefni();
    } else {
      alert('Failed to delete PDF');
    }
  };

  return (
    <div className="admin-lesefni-manager">
      <h1>Admin Lesefni Manager</h1>
      <form onSubmit={handleUpload}>
        <input type="file" onChange={handleFileChange} />
        <button type="submit">Upload PDF</button>
      </form>
      <ul>
        {lesefni.map((pdf) => (
          <li key={pdf.filename}>
            <a href={pdf.url} target="_blank" rel="noopener noreferrer">{pdf.filename}</a>
            <button onClick={() => handleDelete(pdf.filename)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminLesefniManager;
