import React, { useState, useEffect } from 'react';

const AdminReviewManager = () => {
  const [reviews, setReviews] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchReviews();
  }, []);

  const fetchReviews = async () => {
    try {
      const response = await fetch('/api/reviews', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.ok) {
        const data = await response.json();
        setReviews(data);
      } else {
        const data = await response.json();
        setError(data.error);
      }
    } catch (error) {
      console.error('Error fetching reviews:', error);
      setError('Error fetching reviews');
    }
  };

  const handleReviewAction = async (timestamp, action) => {
    try {
      const response = await fetch('/api/review-status', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ timestamp, status: action }),
      });

      if (response.ok) {
        fetchReviews(); // Refresh the reviews after an action
      } else {
        const data = await response.json();
        setError(data.error);
      }
    } catch (error) {
      console.error(`Error updating review status:`, error);
      setError('Error updating review status');
    }
  };

  return (
    <div>
      <h1>Admin Review Manager</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <ul>
        {reviews.map((review, index) => (
          <li key={index}>
            <p><strong>Name:</strong> {review.name}</p>
            <p><strong>Email:</strong> {review.email}</p>
            <p><strong>Review:</strong> {review.review}</p>
            <p><strong>Timestamp:</strong> {new Date(review.timestamp).toLocaleString()}</p>
            <p><strong>Status:</strong> {review.status || 'Pending'}</p>
            {review.status !== 'approved' && (
              <button onClick={() => handleReviewAction(review.timestamp, 'approved')}>
                Approve
              </button>
            )}
            {review.status !== 'rejected' && (
              <button onClick={() => handleReviewAction(review.timestamp, 'rejected')}>
                Reject
              </button>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminReviewManager;
