// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Main layout container */
.main-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Full viewport height */
  }
  
  /* Content area will take the remaining space between navbar and footer */
  .content {
    flex-grow: 1; /* Allows content to grow and push the footer down */
    padding: 20px;
    box-sizing: border-box;
  }
  
  /* Footer */
  .footer {
    background-color: #edf5ed;
    color: #2f3776;
    padding: 20px 0;
    width: 100%;
    text-align: center;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for elevation */
    position: relative;
    flex-shrink: 0; /* Prevent shrinking */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/MainLayout.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB,EAAE,yBAAyB;EAC9C;;EAEA,yEAAyE;EACzE;IACE,YAAY,EAAE,oDAAoD;IAClE,aAAa;IACb,sBAAsB;EACxB;;EAEA,WAAW;EACX;IACE,yBAAyB;IACzB,cAAc;IACd,eAAe;IACf,WAAW;IACX,kBAAkB;IAClB,0CAA0C,EAAE,gCAAgC;IAC5E,kBAAkB;IAClB,cAAc,EAAE,sBAAsB;EACxC","sourcesContent":["/* Main layout container */\n.main-layout {\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh; /* Full viewport height */\n  }\n  \n  /* Content area will take the remaining space between navbar and footer */\n  .content {\n    flex-grow: 1; /* Allows content to grow and push the footer down */\n    padding: 20px;\n    box-sizing: border-box;\n  }\n  \n  /* Footer */\n  .footer {\n    background-color: #edf5ed;\n    color: #2f3776;\n    padding: 20px 0;\n    width: 100%;\n    text-align: center;\n    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for elevation */\n    position: relative;\n    flex-shrink: 0; /* Prevent shrinking */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
