import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import './DashboardNavbar.css';

const DashboardNavbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('userToken');
    navigate('/signin');
  };

  return (
    <nav className="dashboard-navbar">
      <ul className="dashboard-nav-links">
        <li>
          <NavLink to="/dashboard/videos" className={({ isActive }) => (isActive ? 'dashboard-navlink dashboard-navlink-active' : 'dashboard-navlink')}>
            Myndbönd
          </NavLink>
        </li>
        <li>
          <NavLink to="/dashboard/reading-material" className={({ isActive }) => (isActive ? 'dashboard-navlink dashboard-navlink-active' : 'dashboard-navlink')}>
            Lesefni
          </NavLink>
        </li>
        <li>
          <NavLink to="/dashboard/extra-material" className={({ isActive }) => (isActive ? 'dashboard-navlink dashboard-navlink-active' : 'dashboard-navlink')}>
            Aukaefni
          </NavLink>
        </li>
        <li>
          <NavLink to="/dashboard/write-reviews" className={({ isActive }) => (isActive ? 'dashboard-navlink dashboard-navlink-active' : 'dashboard-navlink')}>
            Skrifa umsögn
          </NavLink>
        </li>

      </ul>
      <button onClick={handleLogout} className="dashboard-logout-button">
        Útskráning
      </button>
    </nav>
  );
};

export default DashboardNavbar;
