import React, { useState, useEffect } from "react";
import './AdminHomeEditor.css';

const AdminHomeEditor = () => {
  const [homeContent, setHomeContent] = useState({ title: '', description: '' });

  useEffect(() => {
    fetch('/api/homeContent')
      .then(response => response.json())
      .then(data => setHomeContent(data));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHomeContent(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch('/api/homeContent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(homeContent),
      credentials: 'include'
    });
    if (response.ok) {
      alert('Content updated successfully');
    } else {
      alert('Failed to update content');
    }
  };

  return (
    <div className="admin-about-editor">
      <h2>Edit Home page info</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Titill</label>
          <input type="text" name="title" value={homeContent.title} onChange={handleChange} />
        </div>
        <div>
          <label>Texti</label>
          <textarea name="description" value={homeContent.description} onChange={handleChange} />
        </div>
        <button type="submit">Save</button>
      </form>
    </div>
  );
};

export default AdminHomeEditor;
