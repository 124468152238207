import React from "react";
import './Home.css'; // Import the CSS file for the Home page
import { NavLink } from 'react-router-dom';

import one from '../assets/myndir/1.png';
import two from '../assets/myndir/2.png';
import three from '../assets/myndir/3.png';
import four from '../assets/myndir/4.png';
import five from '../assets/myndir/5.png';
import six from '../assets/myndir/6.png';

const Home = () => {
  return (
    <div className="home-page">
      {/* Top Image Section with Button Inside */}
      <div className="image-container">
        <img src={one} alt="Illustration 1" className="background-image" />
        <NavLink to="/buy" className="btn btn-2">
          Kaupa námskeið
        </NavLink>
      </div>

      {/* Home Section */}
      <div id="home" className="about-container">
        <img src={two} alt="Illustration 2" className="section-image" />
      </div>

      {/* About Section */}
      <div id="about" className="included-container">
        <img src={three} alt="Illustration 3" className="section-image" />
      </div>

      {/* Pros Section */}
      <div id="pros" className="pros-container">
        <img src={four} alt="Illustration 4" className="section-image" />
      </div>

      {/* Contact Section */}
      <div id="contact" className="who-container">
        <img src={five} alt="Illustration 5" className="section-image" />
      </div>

      {/* Final Image */}
      <div className="steinunn">
        <img src={six} alt="Illustration 6" className="section-image" />
      </div>
    </div>
  );
};

export default Home;
