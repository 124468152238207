import React, { useState } from 'react';

const CodeGenerator = () => {
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [message, setMessage] = useState('');

    const generateCode = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let code = '';
        for (let i = 0; i < 5; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            code += characters[randomIndex];
        }
        return code;
    };

    const handleGenerateCode = async () => {
        if (!email) {
            setMessage('Email is required');
            return;
        }

        const newCode = generateCode();
        setCode(newCode);

        try {
            const response = await fetch('/store-code', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, code: newCode }),
            });

            if (response.ok) {
                setMessage('Code stored successfully');
            } else {
                const errorData = await response.json();
                setMessage(`Error: ${errorData.error}`);
            }
        } catch (error) {
            console.error('Error storing code:', error);
            setMessage('Error storing code');
        }
    };

    return (
        <div>
            <h1>Random Code Generator</h1>
            <input
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <button onClick={handleGenerateCode}>Generate Code</button>
            {code && <p>Generated Code: {code}</p>}
            {message && <p>{message}</p>}
        </div>
    );
};

export default CodeGenerator;
