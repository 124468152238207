import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './Reviews.css';

const Reviews = () => {
    const [reviews, setReviews] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const response = await fetch('/api/approved-reviews', {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                });
    
                if (response.ok) {
                    const data = await response.json();
                    console.log("Fetched approved reviews:", data);

                    // Filter for unique reviews based on timestamp
                    const uniqueReviews = data.filter(
                        (review, index, self) =>
                            index === self.findIndex((r) => r.timestamp === review.timestamp)
                    );
                    
                    setReviews(uniqueReviews);
                } else {
                    const errorData = await response.json();
                    setError(errorData.error || "Failed to load reviews");
                }
            } catch (error) {
                console.error('Error fetching approved reviews:', error);
                setError('Error fetching approved reviews');
            }
        };
    
        fetchReviews();
    }, []);

    // Adjust slider settings based on the number of reviews
    const sliderSettings = {
        dots: true,
        infinite: reviews.length > 1, // Disable infinite scrolling if only one review
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    return (
        <Slider {...sliderSettings}>
            {reviews.length > 0 ? (
                reviews.map((review, index) => (
                    <div key={index}> {/* Unique key for each review */}
                        <h3>{review.name || "Anonymous"}</h3>
                        <p>{review.review || "No Content"}</p>
                    </div>
                ))
            ) : (
                <div>Loading reviews...</div>
            )}
        </Slider>
    );
};

export default Reviews;
