import React, { useState, useEffect } from 'react';
import './AdminExtraMaterialManager.css';

const AdminExtraMaterialManager = () => {
  const [extraMaterials, setExtraMaterials] = useState([]);
  const [file, setFile] = useState(null);

  useEffect(() => {
    fetchExtraMaterials();
  }, []);

  const fetchExtraMaterials = async () => {
    const response = await fetch('/api/extramaterial');
    const data = await response.json();
    setExtraMaterials(data);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('extramaterial', file);

    const response = await fetch('/api/extramaterial/upload', {
      method: 'POST',
      body: formData,
      credentials: 'include'
    });

    if (response.ok) {
      alert('Extra material uploaded successfully');
      fetchExtraMaterials();
    } else {
      alert('Failed to upload extra material');
    }
  };

  const handleDelete = async (filename) => {
    const response = await fetch(`/api/extramaterial/${filename}`, {
      method: 'DELETE',
      credentials: 'include'
    });

    if (response.ok) {
      alert('Extra material deleted successfully');
      fetchExtraMaterials();
    } else {
      alert('Failed to delete extra material');
    }
  };

  return (
    <div className="admin-extra-material-manager">
      <h1>Admin Extra Material Manager</h1>
      <form onSubmit={handleUpload}>
        <input type="file" onChange={handleFileChange} />
        <button type="submit">Upload Extra Material</button>
      </form>
      <ul>
        {extraMaterials.map((material) => (
          <li key={material.filename}>
            <a href={material.url} target="_blank" rel="noopener noreferrer">{material.filename}</a>
            <button onClick={() => handleDelete(material.filename)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminExtraMaterialManager;
