import React, { useState, useEffect } from "react";
import './AdminBenefitEditor.css';

const AdminBenefitEditor = () => {
  const [benefitContent, setBenefitContent] = useState({ title: '', benefits: [] });

  useEffect(() => {
    fetch('/api/benefitContent')
      .then(response => response.json())
      .then(data => setBenefitContent(data));
  }, []);

  const handleTitleChange = (e) => {
    setBenefitContent(prevState => ({
      ...prevState,
      title: e.target.value
    }));
  };

  const handleBenefitChange = (index, value) => {
    const newBenefits = [...benefitContent.benefits];
    newBenefits[index] = value;
    setBenefitContent(prevState => ({
      ...prevState,
      benefits: newBenefits
    }));
  };

  const addBenefit = () => {
    setBenefitContent(prevState => ({
      ...prevState,
      benefits: [...prevState.benefits, ""]
    }));
  };

  const removeBenefit = (index) => {
    const newBenefits = benefitContent.benefits.filter((_, i) => i !== index);
    setBenefitContent(prevState => ({
      ...prevState,
      benefits: newBenefits
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch('/api/benefitContent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(benefitContent),
      credentials: 'include'
    });
    if (response.ok) {
      alert('Content updated successfully');
    } else {
      alert('Failed to update content');
    }
  };

  return (
    <div className="admin-benefit-editor">
      <h2>Edit Helstu kostir</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Title</label>
          <input type="text" name="title" value={benefitContent.title} onChange={handleTitleChange} />
        </div>
        <div>
          <label>Benefits</label>
          {benefitContent.benefits.map((benefit, index) => (
            <div key={index} className="benefit-item">
              <input
                type="text"
                value={benefit}
                onChange={(e) => handleBenefitChange(index, e.target.value)}
              />
              <button type="button" onClick={() => removeBenefit(index)}>Remove</button>
            </div>
          ))}
          <button type="button" onClick={addBenefit}>Add Benefit</button>
        </div>
        <button type="submit">Save</button>
      </form>
    </div>
  );
};

export default AdminBenefitEditor;
