import React, { useEffect, useState } from "react";
import './AdminFaqEditor.css';

const AdminFaqEditor = () => {
  const [faqContent, setFaqContent] = useState([]);

  useEffect(() => {
    const fetchFaqContent = async () => {
      const response = await fetch('/api/faqContent');
      const data = await response.json();
      setFaqContent(data);
    };

    fetchFaqContent();
  }, []);

  const handleChange = (index, field, value) => {
    const updatedFaqContent = [...faqContent];
    updatedFaqContent[index][field] = value;
    setFaqContent(updatedFaqContent);
  };

  const handleAdd = () => {
    setFaqContent([...faqContent, { question: "", answer: "" }]);
  };

  const handleRemove = (index) => {
    const updatedFaqContent = faqContent.filter((_, i) => i !== index);
    setFaqContent(updatedFaqContent);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch('/api/faqContent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(faqContent),
      credentials: 'include'
    });
    if (response.ok) {
      alert('Content updated successfully');
    } else {
      alert('Failed to update content');
    }
  };

  return (
    <div className="admin-faq-editor">
      <h2>Edit Algengar spurningar</h2>
      <form onSubmit={handleSubmit}>
        {faqContent.map((faq, index) => (
          <div key={index} className="faq-item">
            <div>
              <label>Question</label>
              <input
                type="text"
                value={faq.question}
                onChange={(e) => handleChange(index, 'question', e.target.value)}
                className="faq-input"
              />
            </div>
            <div>
              <label>Answer</label>
              <textarea
                value={faq.answer}
                onChange={(e) => handleChange(index, 'answer', e.target.value)}
                className="faq-textarea"
              />
            </div>
            <button type="button" onClick={() => handleRemove(index)} className="remove-button">Remove</button>
          </div>
        ))}
        <button type="button" onClick={handleAdd} className="add-button">Add FAQ</button>
        <button type="submit" className="save-button">Save</button>
      </form>
    </div>
  );
};

export default AdminFaqEditor;
