import React from 'react';
import './Dashboard.css'; // Import the CSS file for the dashboard

const Dashboard = () => {
  return (
    <div className="dashboard-container">
      <h1>Ungirofurhugar</h1>
      <p>Velkomin á námskeiðið Ungir ofurhugar</p>
      {/* Add your dashboard content here */}
    </div>
  );
};

export default Dashboard;
