import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { NavLink } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navbar">
      {/* <div className="logo-container">
        <HashLink smooth to="/home#" className="navlink" aria-label="Home">
          <Logo className="logo" />
        </HashLink>
      </div>
       */}
      <button className="hamburger" onClick={toggleMenu}>
        &#9776; {/* Hamburger menu icon */}
      </button>

      <div className={`nav-links-container ${menuOpen ? 'open' : ''}`}>
        <ul className="nav-links">
          <li>
            <HashLink smooth to="/home#" className="navlink">
              Heim
            </HashLink>
          </li>
          {/* <li>
            <HashLink smooth to="/home#about" className="navlink">
              Um mig
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="/home#pros" className="navlink">
              Helstu kostir
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="/home#contact" className="navlink">
              Upplýsingar
            </HashLink>
          </li> */}
        
          <li>
            <NavLink to="/reviews" className="navlink">
              Umsagnir
            </NavLink>
          </li>
          <li>
            <NavLink to="/buy" className="navlink">
              Kaupa námskeið
            </NavLink>
            {/* <span className="sale-badge"> Black Friday afsláttur</span> */}
          </li>
          <li>
            <NavLink to="/signin" className="navlink-innskraning">
              Innskráning
            </NavLink>
          </li>
          
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;

