import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/full-screen/lib/styles/index.css';
import './ReadingMaterial.css';

const ReadingMaterial = () => {
  const [files, setFiles] = useState([]);
  const [visibleFileId, setVisibleFileId] = useState(null);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axios.get('/api/readingmaterial');
        setFiles(response.data);
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    };

    fetchFiles();
  }, []);

  const toggleFileVisibility = (id) => {
    setVisibleFileId((prevId) => (prevId === id ? null : id));
  };

  const toolbarPluginInstance = toolbarPlugin();
  const fullScreenPluginInstance = fullScreenPlugin();
  const { Toolbar } = toolbarPluginInstance;

  return (
    <div className="reading-container">
      <h1 className="reading-title">Lesefni</h1>
      {files.map((file) => (
        <div key={file.id} className="file-item">
          <div className="file-header">
            <h3>{file.title}</h3>
            <button onClick={() => toggleFileVisibility(file.id)}>
              {visibleFileId === file.id ? 'Fela' : 'Skoða'}
            </button>
          </div>
          {visibleFileId === file.id && (
            <div className="file-viewer">
              <div
                className="rpv-core__viewer"
                style={{
                  border: '1px solid rgba(0, 0, 0, 0.3)',
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                <div
                  style={{
                    alignItems: 'center',
                    backgroundColor: '#eeeeee',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    padding: '4px',
                  }}
                >
                  <Toolbar>
                    {(props) => {
                      const {
                        CurrentPageInput,
                        EnterFullScreen,
                        GoToNextPage,
                        GoToPreviousPage,
                        NumberOfPages,
                        ShowSearchPopover,
                        Zoom,
                        ZoomIn,
                        ZoomOut,
                      } = props;
                      return (
                        <>
                          <div style={{ padding: '0px 2px' }}>
                            <ShowSearchPopover />
                          </div>
                          <div style={{ padding: '0px 2px' }}>
                            <ZoomOut />
                          </div>
                          <div style={{ padding: '0px 2px' }}>
                            <Zoom />
                          </div>
                          <div style={{ padding: '0px 2px' }}>
                            <ZoomIn />
                          </div>
                          <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                            <GoToPreviousPage />
                          </div>
                          <div style={{ padding: '0px 2px', width: '4rem' }}>
                            <CurrentPageInput />
                          </div>
                          <div style={{ padding: '0px 2px' }}>
                            / <NumberOfPages />
                          </div>
                          <div style={{ padding: '0px 2px' }}>
                            <GoToNextPage />
                          </div>
                          <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                            <EnterFullScreen />
                          </div>
                        </>
                      );
                    }}
                  </Toolbar>
                </div>
                <div
                  style={{
                    flex: 1,
                    overflow: 'hidden',
                  }}
                >
                  <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`}>
                    <Viewer fileUrl={file.url} plugins={[toolbarPluginInstance, fullScreenPluginInstance]} />
                  </Worker>
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ReadingMaterial;
