// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-about-editor {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .admin-about-editor h2 {
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  
  .admin-about-editor form {
    display: flex;
    flex-direction: column;
  }
  
  .admin-about-editor form div {
    margin-bottom: 15px;
  }
  
  .admin-about-editor form label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .admin-about-editor form input,
  .admin-about-editor form textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }
  
  .admin-about-editor form textarea {
    height: 200px; /* Adjust height as needed */
    resize: vertical; /* Allows vertical resizing */
  }
  
  .admin-about-editor form button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .admin-about-editor form button:hover {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/AdminAboutEditor.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,wCAAwC;EAC1C;;EAEA;IACE,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;;IAEE,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,aAAa,EAAE,4BAA4B;IAC3C,gBAAgB,EAAE,6BAA6B;EACjD;;EAEA;IACE,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".admin-about-editor {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 20px;\n    background-color: #f9f9f9;\n    border-radius: 8px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  }\n  \n  .admin-about-editor h2 {\n    font-size: 1.5em;\n    margin-bottom: 20px;\n  }\n  \n  .admin-about-editor form {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .admin-about-editor form div {\n    margin-bottom: 15px;\n  }\n  \n  .admin-about-editor form label {\n    margin-bottom: 5px;\n    font-weight: bold;\n  }\n  \n  .admin-about-editor form input,\n  .admin-about-editor form textarea {\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    width: 100%;\n  }\n  \n  .admin-about-editor form textarea {\n    height: 200px; /* Adjust height as needed */\n    resize: vertical; /* Allows vertical resizing */\n  }\n  \n  .admin-about-editor form button {\n    padding: 10px 20px;\n    background-color: #007bff;\n    color: #fff;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n  }\n  \n  .admin-about-editor form button:hover {\n    background-color: #0056b3;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
