// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Filename - components/AdminUserEditor.css */

.admin-user-editor {
    padding: 20px;
  }
  
  .admin-user-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .user-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .user-table th, .user-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .user-table th {
    background-color: #f2f2f2;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/AdminUserEditor.css"],"names":[],"mappings":"AAAA,8CAA8C;;AAE9C;IACI,aAAa;EACf;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,yBAAyB;EAC3B;;EAEA;IACE,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* Filename - components/AdminUserEditor.css */\n\n.admin-user-editor {\n    padding: 20px;\n  }\n  \n  .admin-user-title {\n    font-size: 24px;\n    margin-bottom: 20px;\n  }\n  \n  .user-table {\n    width: 100%;\n    border-collapse: collapse;\n  }\n  \n  .user-table th, .user-table td {\n    border: 1px solid #ddd;\n    padding: 8px;\n  }\n  \n  .user-table th {\n    background-color: #f2f2f2;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
